.profile-edit {
  margin-bottom: 40px;

  .profile-edit-header {
    font-weight: 500;
    font-size: 18px;
    margin: 20px 0;
  }

  .form-label {
    font-size: 90%;
  }
}

.btn {
  z-index: 1 !important;
}