@import "../../../../../../styles/vars_mixins";

.wallet-ecency {
  @include wallet;

  .get-points {
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;

    @include themify(day) {
      @apply bg-light-200;
    }

    @include themify(night) {
      @apply bg-dark-200;
    }

    @media (min-width: $lg-break) {
      flex-direction: row;
    }

    .points-types {
      .points-types-title {
        font-weight: 500;
        margin-bottom: 16px;
        text-align: center;

        @media (min-width: $lg-break) {
          text-align: initial;
        }

        @include themify(day) {
          @apply text-gray-charcoal;
        }

        @include themify(night) {
          @apply text-blue-powder;
        }
      }

      .points-types-list {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @media (min-width: $lg-break) {
          justify-content: flex-start;
          flex-grow: 1;
          flex-shrink: 0;
          margin-bottom: 0;
        }

        .point-reward-type {
          align-items: center;
          border-radius: 50%;
          height: 40px;
          justify-content: center;
          width: 40px;
          @apply text-gray-warm;
          position: relative;
          display: inline-flex;
          margin-bottom: 20px;

          &:not(:last-child) {
            margin-right: 20px;
          }

          @media (min-width: $lg-break) {
            margin-bottom: 0;

            &:not(:last-child) {
              margin-right: 30px;
            }
          }

          svg {
            height: 18px;
          }

          @include themify(day) {
            @apply bg-white;
            @apply text-gray-steel;
          }

          @include themify(night) {
            @apply bg-blue-metallic;
            @apply text-blue-metallic-40-light;
          }

          .reward-num {
            @apply bg-blue-dark-sky;
            position: absolute;
            right: -30%;
            bottom: 0;
            @apply text-white;
            font-size: 10px;
            border-radius: 24px;
            width: 24px;
            padding: 1px 0;
            text-align: center;
          }
        }
      }
    }

    .buy-points {
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: $lg-break) {
        justify-content: flex-end;
      }

      a {
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .p-transaction-list {
    margin: 0 0 20px 0;

    .transaction-list-header {
      margin: 30px 10px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      @media (min-width: $sm-break) {
        flex-direction: row;
      }

      h2 {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 10px 0;
        text-align: center;
        text-transform: uppercase;

        @media (min-width: $sm-break) {
          margin-bottom: 0;
        }

        @include themify(day) {
          @apply text-gray-charcoal;
        }

        @include themify(night) {
          @apply text-blue-powder;
        }
      }

      select {
        width: 240px;
      }
    }

    .transaction-list-item {
      border-radius: 8px;
      box-sizing: content-box;
      display: flex;
      padding: 16px;

      .transaction-icon {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        margin-right: 10px;
        width: 32px;

        svg {
          width: 16px;
          height: 16px;
        }

        @include themify(day) {
          @apply bg-white;
          @apply text-gray-steel;
        }

        @include themify(night) {
          @apply bg-blue-metallic;
          @apply text-blue-metallic-40-light;
        }
      }

      .transaction-title {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        width: 30%;

        .transaction-name {
          @include themify(day) {
            @apply text-gray-steel;
          }

          @include themify(night) {
            @apply text-blue-metallic;
          }
        }

        .transaction-date {
          font-size: 12px;

          @include themify(day) {
            @apply text-silver;
          }

          @include themify(night) {
            @apply text-gray-warm;
          }
        }
      }

      .transaction-numbers {
        @apply text-blue-dark-sky;
        flex-grow: 1;

        font-weight: 500;
        margin-right: 30px;
        text-align: right;

        .number {
          margin-right: 4px;
        }
      }

      .transaction-details {
        @apply text-gray-steel;
        width: 30%;
        word-break: break-all;
      }

      &:nth-child(odd) {
        @include themify(day) {
          @apply bg-light-300;
        }

        @include themify(night) {
          @apply bg-dark-600;
        }
      }

      @include themify(day) {
        &:nth-child(even) {
          .transaction-icon {
            @apply bg-light-300 dark:bg-dark-600;
          }
        }
      }
    }

    .empty-list {
      margin: 0 10px 0;
    }
  }
}
